import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DeliveryRouteService } from '../../utilities/services/DeliveryRouteService';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { Box, Typography } from '@mui/material';
import {DeliveryRoute} from "../../utilities/types/DeliveryRouteTypes";
import CompletedRoute from "../../screens/RouteBuilder/components/CompletedRoute/CompletedRoute";
import Colors from "../../assets/Colors";

const ExternalReportLink = () => {
    const { routeId } = useParams<{ routeId: string }>();
    const [routeData, setRouteData] = useState<DeliveryRoute | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRouteData = async () => {
            if (routeId) {
                try {
                    const data = await DeliveryRouteService.getRouteByRouteId(routeId);
                    setRouteData(data);
                } catch (error) {
                    console.error("Failed to fetch route data", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchRouteData();
    }, [routeId]);

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    backgroundColor: '#fff',
                    textAlign: 'center'
                }}
            >
                <PacmanLoader color={Colors.napaBlue} size={80} />
                <Typography
                    variant="h6"
                    sx={{
                        marginTop: '20px',
                        color: '#555',
                        fontFamily: 'Arial, sans-serif',
                        letterSpacing: '0.05em'
                    }}
                >
                    Loading, please wait...
                </Typography>
            </Box>
        );
    }

    return (
        <div>
            {routeData && routeData.routeStatus === "complete" ?
                <CompletedRoute
                    completedRouteData={[routeData]}
                    openCompletedRoute={true} // Keep open, but will not render as modal
                    setOpenCompletedRoute={() => {}}
                    isModal={false}
                /> :
                <h1>No route data available or route not complete</h1>
            }
        </div>
    );
};

export default ExternalReportLink;