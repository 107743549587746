import React, { Fragment, useContext } from 'react'
import './App.css'
import SignIn from './screens/SignIn/SignIn'
import UserManagement from './screens/UserManagement/UserManagement'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { AuthContext, AuthProvider } from './utilities/contexts/AuthContext'
import ForgotPassword from './screens/ForgotPassword/ForgotPassword'
import ResetPassword from './screens/ResetPassword/ResetPassword'
import CompleteRegistration from './screens/NewUser/CompleteRegistration'
import theme from './theme'
import { ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from './utilities/contexts/SnackbarContext'
import NavigationBar from './components/NavigationBar/NavigationBar'
import RouteBuilder from './screens/RouteBuilder/RouteBuilder'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import TermsAndConditions from './screens/TermsAndConditions/TermsAndConditions'
import { StoreProvider } from './utilities/contexts/StoreContext'
import Whiteboard from './screens/Whiteboard/Whiteboard'
import { CircularProgress } from '@mui/material'
import Reports from './screens/Reports/Reports'
import MyFleet from './screens/MyFleet/Myfleet'
import VehicleDetails from './screens/MyFleet/components/VehicleDetails/VehicleDetails'
import DigitalReturns from './screens/DigitalReturns/DigitalReturns'
import { LanguageProvider } from './utilities/contexts/LanguageContext'
import { useFlags } from 'launchdarkly-react-client-sdk'
import ExternalReportLink from "./components/ExternalReportLink/ExternalReportLink";
// import { initializeApp } from 'firebase/app';
// import { getMessaging } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''

const queryClient = new QueryClient()

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_GCP_PROJECT,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSENGER_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// };

// Initialize Firebase

// const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);
// const messaging = getMessaging(app);

function App() {
  Amplify.configure({
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    identityPoolRegion: process.env.REACT_APP_COGNITO_USER_POOL_ID
  })

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider>
              <LanguageProvider>
                <AuthProvider>
                  <Navigator />
                </AuthProvider>
              </LanguageProvider>
            </SnackbarProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  )
}

export const Navigator = () => {
  const { currentUser, termsAccepted, isInitializingCurrentUser, isInitializingTerms } =
    useContext(AuthContext)

  const { isReturnsEnabled, isPowerBiReportEnabled } = useFlags()

  if (isInitializingCurrentUser || isInitializingTerms)
    return (
      <div className="Loading-container">
        <CircularProgress />
      </div>
    )

  if (currentUser) {
    if (termsAccepted) {
      return (
        <Fragment>
          <StoreProvider>
            <NavigationBar />
            <Routes>
              <Route index element={<Navigate to="/Whiteboard" />} />
              <Route path="/Whiteboard" element={<Whiteboard />} />
              <Route path="/RouteBuilder" element={<RouteBuilder />} />
              <Route path="/UserManagement" element={<UserManagement />} />
              {isPowerBiReportEnabled && <Route path="/Reports" element={<Reports />} />}
              <Route path="/MyFleet" element={<MyFleet />} />
              {isReturnsEnabled && <Route path="/DigitalReturns" element={<DigitalReturns />} />}
              <Route path="/VehicleDetails" element={<VehicleDetails />} />
              <Route path="/RouteDetail/:routeId" element={<ExternalReportLink />} />
              <Route path="*" element={<p>Page Not Found</p>} />
            </Routes>
          </StoreProvider>
        </Fragment>
      )
    } else {
      return <TermsAndConditions />
    }
  } else if (currentUser === undefined) {
    return <div>Loading</div>
  } else {
    return (
      <Routes>
        <Route index element={<SignIn />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/Registration" element={<CompleteRegistration />} />
        <Route path="*" element={<SignIn />} />
      </Routes>
    )
  }
}

export default App
