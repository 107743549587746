import { LanguageValues } from './LanguageTypes'

export const englishLanguageValues: LanguageValues = {
  address: 'Address',
  add: 'Add',
  ok: 'OK',
  invoiceExample: 'ex: 12345-1234',
  confirmPassword: 'Confirm Password*',
  route: 'route',
  startDate: 'Start Date',
  invoiceNumber: 'Inv #',
  itemNumber: 'ITEM #',
  lineAbbreviation: 'LINE',
  description: 'DESCRIPTION',
  quantity: 'QTY',
  routeNumber: 'Route #',
  yourStores: 'Your Stores',
  storeNumber: 'Store #',
  completed: 'Completed',
  addInvoice: 'Add Invoice',
  totalStops: 'Total Stops:',
  cancelRoute: 'Cancel Route',
  no: 'No',
  yes: 'Yes',
  cancel: 'Cancel',
  doordashDriver: 'Driver: ',
  doordashPhoneNumber: 'Phone: ',
  cancelDoordash: 'Cancel Doordash',
  signature: 'Signature',
  deliveryPhoto: 'Delivery Photo',
  invoice: 'Invoice #',
  invoiceTitle: 'Invoices',
  routesTitle: 'Routes',
  delivered: 'Delivered: ',
  estimatedDelivered: 'Delivery ETA: ',
  estimatedPickUp: 'Pickup ETA: ',
  deliveredAt: 'Delivered at',
  editAllInvoice: 'Edit all invoices for',
  remove: 'Remove',
  removeInvoice: 'Remove Invoice',
  editInvoice: 'Edit Invoice(s)',
  back: 'Back',
  complete: 'Complete',
  done: 'Done',
  termsAndConditions: 'Terms and Conditions',
  lastUpdated: 'Last Updated March 8,2023',
  decline: 'Decline',
  accept: 'Accept',
  createPassword: 'Create Password',
  passwordRequirements: 'Password requirements',
  alphaNumeric: 'Must include at least one (1) number, one (1) upper case letter and one (1) lower case letter.',
  createSignInPassword: 'Create Password & Sign In',
  resetPassword: 'Reset Password?',
  authenticationCode: 'Enter your authentication code sent to',
  sendAnotherCode: 'Send another code',
  resetMyPassword: 'Reset my password',
  forgotYourPassword: 'Forgot your password?',
  save: 'Save',
  completeRegistration: 'Complete Registration',
  tempPassword: 'Enter the temporary password sent to the email you provided the NSSA team.',
  register: 'Register',
  signIn: 'Sign In',
  keepMeSignIn: 'Keep me signed in.',
  newNAPA: 'New To NAPA Store Systems',
  addUser: 'Add User',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  confirm: 'Confirm',
  editUser: 'Edit User',
  status: 'Status',
  actions: 'Actions',
  admin: 'Admin',
  disabled: 'Disabled',
  edit: 'Edit',
  disable: 'Disable',
  enable: 'Enable',
  signOut: 'Sign Out',
  signOutDesc: 'Are you sure you want to sign out?',
  signOutText: 'SIGN OUT',
  cancelText: 'CANCEL',
  storeSupport: 'Store Support',
  contactUs: 'For NSSA issues, please contact us at ',
  urgentCalls: 'For urgent issues, please call ',
  doordashContact: 'For DoorDash delivery issues (lost parts, problem with dasher, etc.), please contact drive support at ',
  ddUrgentCall: 'For urgent delivery issues, please call ',
  supportMail: 'NAPA_StoreSupport@genpt.com',
  storeSystems: 'STORE SYSTEMS',
  update: 'Update',
  updateRoute: 'Update Route',
  storeSystemsTitleCase: 'Store Systems',
  dvir: 'DVIR',
  errorMessage: 'Something went wrong. Please try again.',
  noVehicles: 'No Vehicles Available',
  red: 'Red',
  yellow: 'Yellow',
  green: 'Green',
  odometer: 'Odometer',
  odometerPlaceholder: 'ex. 123456789',
  close: 'Close',
  trackMyOrder: 'Track My Order',
  checkEngineLight: 'Check Engine Light',
  inspectionCompletedBy: 'Inspection Completed By:',
  noInspectionCompleted: 'No Inspection Completed',
  completedOn: 'Completed On:',
  warning: 'Warning',
  error: 'error',
  success: 'success',
  notes: 'Notes',
  comment: 'Comment',
  optional: 'Optional',
  required: 'Required',
  myFleet: 'My Fleet',
  currentStore: 'Current Store',
  selectStore: 'Select Store',
  errorDeliveryRoutes: 'Error retrieving delivery routes',
  errorUserRoutes: "Error retrieving user's delivery routes",
  errorInvoice: 'Error retrieving invoices',
  errorInspectionReport: 'Error retrieving inspection reports',
  errorVehicles: 'Error retrieving vehicles',
  errorTnC: 'Error retrieving latest terms and conditions',
  errorUserTnC: 'Error retrieving user terms and conditions',
  errorActiveDrivers: 'Error retrieving active drivers',
  errorUsers: 'Error retrieving users',
  errorSignOut: 'Error signing out',
  errorEmail: 'Incorrect email or username. Please enter a valid email address or username',
  contactAdmin: 'does not have sufficient permissions; please contact your administrator',
  incorrectPassword: 'Incorrect username and/or password. Try again or Reset your password',
  accountDisabled: 'This account has been disabled. Please see store admin to re-enable it',
  tryAgainOrReset: 'Please try again later or click here to reset your password',
  serviceError: 'Unknown Service Error',
  errorAuthentication: 'No user has been Authenticated',
  cognito: 'Error signing out of cognito',
  unknownError: 'Unknown error occurred, try again later',
  mailSentToMail: 'Success! A link has been sent if your email address exists in our system.',
  associatedUser: 'Enter the username associated with your account',
  sendEmail: 'Send Email',
  buildRoute: 'to build a route',
  routeBuilder: 'Route Builder',
  errorVehicle: 'Vehicle name is required.',
  errorYear: 'Year is required.',
  errorMake: 'Make is required.',
  errorModel: 'Model is required.',
  errorVin: 'VIN number is required.',
  errorLicense: 'License plate is required.',
  errorColor: 'Vehicle color is required.',
  successVehicle: 'Success! Vehicle added',
  successUpdated: 'Success! Vehicle updated',
  successDisabled: 'Success! Vehicle disabled',
  addVehicle: 'Add Vehicle',
  vehicle: 'Vehicle Name (required)',
  ymmc: 'Year/Make/Model/Color (required)',
  vin: 'VIN (required)',
  license: 'License Plate (required)',
  odometerOptional: 'Odometer (optional)',
  lastInspection: 'Last Inspection',
  inspectionReport: 'Inspection Reports',
  searchInspection: 'Search by date, completed by or odometer',
  result: 'Results for',
  redMessage: 'Require immediate attention',
  yellowMessage: 'May need future attention',
  viewReport: 'View Report',
  disableVehicle: 'Disable Vehicle',
  confirmDisable: 'Are you sure you want to disable Vehicle Name?',
  searchVehicles: 'Search by vehicle name, make, model, or other keyword',
  newUser: 'New User',
  existingAccount: 'Already have an account?',
  successPassword: 'Success! Your password has been updated',
  preparingSomething: 'We’re preparing something amazing for you!',
  inProgress: 'Under Construction',
  routeCreated: ' has been created.',
  errorRouteCreation: 'Error creating route, refresh page and try again',
  username: 'Please enter a valid email address or username.',
  password: 'Please enter a password',
  myDrivers: 'My Drivers',
  import: 'Import',
  whiteboard: 'Whiteboard',
  reports: 'Reports',
  createdAt: 'Delivery Created at',
  totalInvoices: 'Total Invoices:',
  errorRouteData: 'Error retrieving Route data!',
  noRoutes: 'No Active Routes',
  map: 'Map',
  userDisabled: 'User disabled',
  fName: 'First name is required.',
  lName: 'Last name is required.',
  validEmail: 'Please enter a valid email address.',
  validUser: 'Please enter a valid username.',
  invalidPhone: 'Invalid phone number.',
  selectDefaultStore: 'Please select a default store.',
  userType: 'Please select a user type.',
  errorDriver: 'Error creating new driver',
  emailFirst: 'Success! An email as been sent to',
  emailSecond: 'to complete registration.',
  userExist: 'Username is already taken',
  defaultStore: 'Select Default Store',
  allStores: 'Select All Store(s)',
  userTypeTitle: 'User Type',
  driver: 'Driver',
  infoText: 'You can use the following characters Letters Numbers “!”, “@”, “#”, “.” Your username must be between 6-14 characters',
  notUnique: 'Emails do not have to be unique!',
  newDriver: 'Create New Driver',
  store: 'Default Store',
  csvUpload: 'Select a CSV file to upload',
  drag: 'or Drag and Drop it here',
  importUser: 'Import Users',
  csvFormat: 'Upload up to XX drivers at the same time. Upload a file in CSV format that includes at least the name, email, and default store for each driver.',
  downloadFormat: 'Download our sample CSV format',
  userDisable: 'User is disabled.',
  errorDisable: 'Error disabling user.',
  userEnabled: 'User is enabled.',
  errorEnable: 'Error enabling user.',
  enabled: 'Are you sure you would like to',
  invoices: 'invoice(s)',
  errorUpdateDriver: 'Error updating driver information',
  assignedStore: 'Assigned Stores',
  selectStoreTitle: 'Select Stores',
  phoneNumber: 'Phone Number',
  enableUser: 'Enable User',
  disableUser: 'Disable User',
  statusDelivered: 'Delivered',
  statusInprogress: 'In-Progress',
  statusPending: 'Assigned',
  statusDone: 'Complete',
  searchRoute: 'Search/Filter by Route #, driver, status, Customer, Invoice #, or Part #',
  editRoute: 'Edit Route',
  viewDetails: 'View Details',
  zeroRoutes: 'There are no routes available.',
  pdf: 'Download as PDF',
  print: 'Print Name',
  noInvoice: 'No Invoices Selected',
  none: 'None',
  validInvoice: 'Please enter a valid invoice number',
  invoiceAdded: 'has been added.',
  search: 'Search',
  reset: 'Reset',
  searchInvoice: 'Search/Filter by Invoice #, Customer, Part #, Service Level, or Part Description',
  duplicateDoordash:
    'Doordash cannot create another route with a route id that has already been quoted. You must create a new route to if you want to ship with doordash',
  thirdParty: 'Third-Party Delivery',
  deliveryCost: 'Delivery Cost:',
  deliveryHandling: 'Delivery Handling',
  acceptQuote: 'Accept Quote',
  errorDoordash: 'Error creating quote the doordash quote - route can still be assigned to a driver',
  errorDistance: 'Allowed distance between addresses exceeded',
  invalidNumber: 'The invoice has an Invalid Phone Number for Customer',
  errorUpdate: 'Error updating route, refresh page and try again.',
  launch: 'Launch Third-Party Delivery',
  saveChanges: 'Save Changes',
  errorStops: 'Too many stops. Please try again. (Maximum of 24 invoices allowed).',
  errorCancel: 'Error cancelling the route.',
  errorCancelling: 'Delivery cannot be cancelled/updated after a dasher has been assigned',
  cancelModal: 'Are you sure you want to cancel the route?',
  userManagement: 'User Management',
  usernameText: 'Username',
  passwordText: 'Password',
  emailUsername: 'Email/ Username',
  temporaryPassword: 'Temporary password',
  codeText: 'Authentication Code',
  newPassword: 'New Password',
  charatersLongText: 'Passwords must be at least 8 charaters long.',
  showMap: 'Show Map',
  hideMap: 'Hide Map',
  customerText: 'CUSTOMER',
  orderedAt: 'Ordered At',
  timeElapsed: 'Time Elapsed',
  priorityInMins: 'Service Level',
  endDate: 'End Date',
  rowsPerPage: 'Rows per page',
  ofText: '1–16 of 16',
  noResults: 'No results found.',
  filtersText: 'Filters',
  lastUpdatedHeader: 'LAST UPDATED',
  errorFindInvoice: 'Failed to find invoice #XXX for store #915202200 in the invoice domain.',
  createRoute: 'Create Route',
  addInvoices: 'Add Invoices',
  noInvoices: 'No Invoices',
  saveInvoices: 'Save Invoices',
  successRoute: 'Success! Route',
  hasCancel: 'has been cancelled.',
  pickupETA: 'Pickup ETA: ',
  deliveryETA: 'Delivery ETA: ',
  pickedUp: 'Picked Up: ',
  pickNote: 'Pick-up Note (optional)',
  deliveryNote: 'Delivery Note (optional)',
  hasUpdated: 'has been updated.',
  nameText: 'Name',
  selectAll: 'Select All',
  searchStore: 'Search Store',
  toText: 'to',
  vehicleName: 'Vehicle Name',
  ymmcHeader: 'Y/M/M/C',
  licensePlate: 'License Plate',
  ymmcTitle: 'YMMC',
  byText: 'by',
  dateText: 'DATE',
  completedBy: 'COMPLETED BY',
  vinNumber: 'VIN Number*',
  vinText: 'VIN',
  whiteboardText: 'Whiteboard',
  changeYourPassword: 'to change your password',
  selectType: 'Select User Type',
  year: 'Year',
  make: 'Make',
  model: 'Model',
  color: 'Color',
  inspectionRequired: 'Inspection Required',
  invalidCode: 'Invalid code please try again',
  estimatedTime: 'Estimated Pickup Time',
  dlvr: 'Dlvr',
  created: 'Created',
  pending: 'Pending',
  enRoute: 'En Route',
  resetAll: 'Reset All',
  selectRange1: 'There are no routes available.',
  selectRange2: 'Select a date range or reset to see routes.',
  returnToStore: 'Return to Store',
  arrivedAt: 'Arrived at',
  editRouteWarningMessage: 'Changes haven’t been saved. Click ‘Update Route’ to save changes or close to undo changes.',
  releaseHold: 'Release Hold',
  placeHold: 'Place Hold',
  markDelivered: 'Mark as Delivered',
  invoiceFlagged: 'Flagged',
  onHold: 'On Hold',
  invoiceHoldNote: 'Note: Invoices on hold are not shown above',
  onHoldTooltip: "Can't create a route with orders On Hold",
  errorRouteCreationInvoiceOnHold: "One or more of the invoices selected are on hold.",
  buildRouteText: 'Build Route',
  successInvoice: 'Success! Invoice #',
  invoiceDeliveryMsg: 'Please select a reason for the invoice being marked as delivered. The invoice will no longer be shown, but you will still be able to view it in TAMS.',
  reason: 'Reason (required)',
  salesReason: 'Management / salesperson delivered',
  untrainedReason: 'Untrained courier / driver',
  invoiceDelivered: 'has been marked as delivered.',
  invoiceFailed: 'We’re sorry, we were unable to mark this invoice as delivered. Please try again.',
  invoiceOnHold: 'has been placed on hold.',
  invoiceRemoved: 'has been removed',
  deliveredBy: 'Delivered by (required):',
  stopAutoDispatched: 'Success! Auto-dispatch has been stopped for Invoice',
  autoDispatched: 'Success! DoorDash Route #id has been created.',
  autoDispatchedError: 'We’re sorry, we were unable to auto-dispatch this invoice. Please try again.',
  stopAutoDispatchedError: 'We’re sorry, we were unable to stop-auto-dispatch this invoice.',
  autoDispatchNow: 'Auto-dispatch Now',
  toTryAgainOrStopAutoDispatchBelow: 'to try again or stop auto-dispatch below',
  dispatchingNow: 'Dispatching Now',
  pleaseClick: 'Please Click',
  stopDispatchNow: 'Stop Auto-dispatch',
  toTryAgain: 'to try again',
  selectToRemoveTheAutoDispatch: 'Select to remove the auto-dispatch timer from this invoice. It cannot be restarted.',
  reasonRequired: 'Reason (Required)',
  selectReason: 'Select a reason',
  autoDispatching: 'AUTO DISPATCHING',
  autoDispatchIn: 'Auto-dispatch in ',
  dispatchError: 'Dispatch Error',
  unassignedInvoice: 'Unassigned Invoices',
  autoDispatchedRoutes: "Auto-Dispatched Routes",
  holdInvoice: 'Hold Invoices',
  actualRoute: 'Actual Route',
  suggestedRoute: 'Suggested Route',
  backToStoreRoutes: 'Back to Store Routes',
  gpsDisclaimer: '*Drivers used GPS which may cause actual route to differ from suggested route created',
  removeInvoicemsg: 'Remove from Invoices available for delivery. You will still be able to see it in TAMS.',
  canceledReason: 'Customer canceled',
  systemReason: 'Phone / system issue',
  pickUpReason: 'Picked up in-store',
  errorRemovingInvoice: 'We’re sorry, we were unable to remove this invoice. Please try again.',
  stopAutoDispatchDialogSubText: 'Please select a reason for stopping auto-dispatch. The auto-dispatch timer will be removed and cannot be restarted.',
  reasonCustomerAddress: 'Customer address hard to find',
  reasonCashCustomer: "Cash customer",
  reasonHazardMaterials: "Hazardous materials",
  reasonTransferPart: " Transfer part",
  reasonPartSpecs: "Part doesn't meet size specifications",
  subReasnPartSpecs: "i.e. over 60 lbs or over 60 inches",
  reasonSystemError: "System error",
  autoDispatchColumn: "Auto-dispatch",
  noCustomStop: 'No stops added yet',
  addStop: 'Add Stop',
  customStop: 'Add Custom Stop'
}
